import { UseApiMutationResult } from '@digital-magic/react-common/lib/api/types'
import { OptionalType, hasValue } from '@digital-magic/ts-common-utils'
import {
  BasicImageFileEntity,
  FileId,
  TranslatedFile,
  TranslatedFileId,
  TranslatedFileList,
  UploadFileResponse
} from '@api/endpoints/files/types'
import { ApiErrorPayload } from '@api/errors'

export const getLargeThumbnailId = (image: BasicImageFileEntity): FileId => image.thumbnailLargeFileId ?? image.id
export const getSmallThumbnailId = (image: BasicImageFileEntity): FileId =>
  image.thumbnailSmallFileId ?? image.thumbnailLargeFileId ?? image.id

export const undefinedTranslatedFileList = (): Partial<TranslatedFileList> => ({
  eng: undefined,
  est: undefined,
  rus: undefined
})

export const convertTranslatedFileListToSingleFile = (v: Partial<TranslatedFileList>): OptionalType<TranslatedFile> =>
  hasValue(v.eng) && hasValue(v.est) && hasValue(v.rus) && v.eng.length == 1 && v.est.length == 1 && v.rus.length == 1
    ? {
        eng: v.eng[0],
        est: v.est[0],
        rus: v.rus[0]
      }
    : undefined

export const useTranslatedFileUpload =
  (uploadFile: UseApiMutationResult<ApiErrorPayload, UploadFileResponse, File>) =>
  async (translatedFile: TranslatedFile): Promise<TranslatedFileId> => {
    const engFileIdPromise = uploadFile.mutateAsync(translatedFile.eng)
    const estFileIdPromise = uploadFile.mutateAsync(translatedFile.est)
    const rusFileIdPromise = uploadFile.mutateAsync(translatedFile.rus)
    return {
      eng: (await engFileIdPromise).id,
      est: (await estFileIdPromise).id,
      rus: (await rusFileIdPromise).id
    }
  }
